<template>
  <div class="Index" v-if="$route.name == '首页'" id="top">
    <!-- 轮播图 -->
    <Carousel autoplay v-model="value2" loop :autoplay-speed="10000">
      <CarouselItem
        v-for="(item, index) in data.banners"
        :key="index"
        style="cursor: pointer"
      >
        <div class="demo-carousel">
          <a :href="item.bannerUrl">
            <img :src="item.bannerThumb" alt="首页banner" />
          </a>
        </div>
      </CarouselItem>
    </Carousel>
    <!-- mens -->
    <div class="mensList">
      <div class="mensBox">
        <div>
          <img src="@/assets/images/mensOne.png" alt="一键购墓" />
          <p class="p1">一键购墓</p>
          <p class="p2">一对一顾问全程陪同指导选墓</p>
        </div>
        <div>
          <img src="@/assets/images/mensTwo.png" alt="推荐商品" />
          <p class="p1">推荐商品</p>
          <p class="p2">好物推荐 平台优选</p>
          <p class="solid"></p>
        </div>
        <div>
          <img src="@/assets/images/mensThree.png" alt="在线祭祀" />
          <p class="p1">在线祭祀</p>
          <p class="p2">祭奠逝去亲人 追思回忆过往</p>
          <p class="solid"></p>
        </div>
        <div>
          <img src="@/assets/images/mensFour.png" alt="在线风水" />
          <p class="p1">在线风水</p>
          <p class="p2">线上测风水 免费查询墓地风水</p>
          <p class="solid"></p>
        </div>
      </div>
    </div>
    <div class="boxx">
      <!-- bodyBox -->
      <div class="bodyBox">
        <!-- 关与韬儒 -->
        <div class="about">
          <div style="height: 20px"></div>
          <p>关于韬儒</p>
          <p>
            {{ (data.staticData && data.staticData.articleIntro) || "" }}
          </p>
          <p @click="routeTo('/about')">
            查看更多 <i class="iconfont iconarrow-right"></i>
          </p>
          <!-- <img src="@/assets/images/about.png" alt="" class="aboutImg" /> -->
          <img
            :src="data.staticData && data.staticData.articleThumb"
            alt=""
            class="aboutImg"
          />
        </div>
      </div>
      <!-- 推荐陵园 -->
      <div class="recommend" id="recommend">
        <p class="recommendTitle">推荐陵园</p>
        <div class="recommendBox">
          <div class="recommendBoxLeft">
            <div class="todayJx">今日精选</div>
            <!-- <div class="swiper-container" id="swiper2"> 
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in [{}, {}, {}, {}]"
                :key="index"
              > -->
            <div @click="route(aLi.companyId)">
              <img :src="aLi.url" alt="" class="imgJx" />
              <p class="swTitle">{{ aLi.name }}</p>
              <p class="swdes">{{ aLi.descript }}</p>
              <p class="swprice">¥{{ aLi.price }}<span>起</span></p>
              <p class="swheig"></p>
              <!-- </div>
              </div>
             </div> -->
              <!-- <div class="swiper-pagination" id="swiper-pagination"></div> -->
            </div>
          </div>
          <div class="recommendBoxRight">
            <div
              class="recommendBoxRightBox"
              v-for="(item, index) in aList"
              :key="index"
              @click="route(item.companyId)"
            >
              <img :src="item.url" alt="" class="imgRig" />
              <div>
                <p>{{ item.name }}</p>
                <p>
                  {{ item.descript }}
                </p>
                <p>
                  <span v-if="item.price">¥{{item.price}}</span><span v-else>暂无定价</span> <span v-if="item.price" style="font-size:14px;color: #838383;">起</span>
                  <!-- <span>¥{{ item.cemeteryStartPrice }}</span
                  >起 -->
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="goIdBox">
        <!-- 金牌店铺- 好物排行 -->
        <div class="goldStore" id="goldStore">
          <div class="goldStoreLeft">
            <p class="goldStoreTitle" @click="routeTo('/goldStoreO')">
              金牌店铺 <icon class="iconfont iconxiangyou1"></icon>
            </p>
            <div class="goldStoreLeftB">
              <div
                class="goldStoreLeftBox"
                v-for="(item, index) in data.businessData"
                :key="index"
                 @click="routeClick(item)"
              >
                <p>{{ item.name }}</p>
                <div>
                  <img :src="item.logo" alt="" />
                  <div>
                    <img
                      v-if="item.goodsList[0]"
                      :src="item.goodsList[0].goodsPic"
                      alt=""
                    />
                    <img
                      v-if="item.goodsList[1]"
                      :src="item.goodsList[1] ? item.goodsList[1].goodsPic : ''"
                      :alt="item.goodsList[1].goodsName"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="goldStoreRight">
            <div class="goldStoreRightTop">
              <p class="goldStoreTitle" @click="routeTo('/rankingList')">
                好物排行 <icon class="iconfont iconxiangyou1"></icon>
              </p>
              <div>
                <div
                  :style="
                    goodsId == index &&
                    'font-weight: 600;color: #299654; border-bottom:2px solid #299654;'
                  "
                  v-for="(item, index) in goodsItem"
                  :key="index"
                  @click="goodsIdFun(index)"
                >
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="goldStoreRightBody">
              <div
                class="goldStoreRightBodyBox"
                v-for="(item, index) in goodList"
                 @click="goodsDetail(item)"
                :key="index"
              >
                <img
                  :src="require('@/assets/images/one' + index + '.png')"
                  alt=""
                  class="topImg"
                />
                <div class="goldStoreRightBodyBoxmain">
                  <img :src="item.goodsPic" alt="" />
                  <div>
                    <p>{{ item.goodsName }}</p>
                    <p>
                      <span>¥{{ item.goodsDiscountPrice }}</span
                      ><span>¥{{ item.goodsCommodityPrice }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 活动专区 -->
      <!-- <div class="active" id="active">
        <img src="@/assets/images/activeBanner.png" alt="寒衣节" />
        <div class="activeBox">
          <div v-for="(item, index) in [{}, {}, {}, {}]" :key="index">
            <img src="@/assets/images/item_img.png" alt="" />
            <p>天寿园陵园成都市看看第…天寿园陵园成都市看看第…</p>
            <p><span>¥155</span><span>¥155</span></p>
          </div>
        </div>
      </div> -->
      <!-- 关爱老人 -->
      <div class="care" id="care">
        <p class="goldStoreTitle">
          关爱老人 <icon class="iconfont iconxiangyou1"></icon>
        </p>
        <div class="careBox">
          <div class="careBoxLeft">
            <!-- <img src="@/assets/images/item_img.png" alt="" /> -->
            <video :src="activeOne[0] && activeOne[0].activitiesThumb" class="img"></video>
            <img
              @click="isShowVideo = true"
              class="videoPlay"
              src="@/assets/images/play.png"
              alt=""
            />
            <p>{{ activeOne[0] && activeOne[0].activitiesTitle }}</p>
          </div>
          <div class="careBoxRight">
            <div
              class="careBoxRightBox"
              v-for="(item, index) in activeTwo"
              :key="index"
              @click="actClick(item.id)"
            >
              <img :src="item.activitiesThumb" alt="" />
              <div>
                <p>{{ item.activitiesTitle }}</p>
                <p v-html="item.activitiesContent">
                </p>
                <p>
                  <span></span
                  ><span>{{ item.updateTime | formatDate("yyyy-MM-dd") }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 广告 -->
      <div class="news"></div>
      <!-- 精选热点 -->
      <div class="hotspot" id="hotspot">
        <p class="goldStoreTitle">
          精选热点 </icon>
        </p>
        <!-- <icon class="iconfont iconxiangyou1"> -->
        <div class="hotspotTit">
          <span v-for="(item, index) in 10" :key="index"></span>
        </div>
        <div class="hotspotBox">
          <div
            class="hotspotBoxSm"
            v-for="(item, index) in selectList"
            :key="index"
             @click="goodsDetail(item)"
          >
            <img :src="item.goodsPic" alt="" />
            <p class="hotspotBoxSmp">{{ item.goodsName }}</p>
            <div class="hotspotBoxPri">
              <div>
                <span>¥{{ item.goodsDiscountPrice }}</span
                ><span>¥{{ item.goodsCommodityPrice }}</span>
              </div>
              <div>销量:{{item.goodsSalesNum || 0 }}</div>
            </div>
          </div>
        </div>
        <Row class="mb20">
          <Page
            class="mt20 mr20 fr"
            size="small"
            show-elevator
            show-sizer
            show-total
            :total="totalO"
            :page-size="searchFormO.pageSize"
            :current.sync="searchFormO.page"
            @on-change="homePageSelectList"
            @on-page-size-change="
              (pageSize) => {
                (searchFormO.pageSize = pageSize), homePageSelectList();
              }
            "
          />
        </Row>
      </div>
      <!-- 猜你喜欢 -->
      <div class="hotspot" id="love" style="margin-bottom: 40px">
        <p class="goldStoreTitle">猜你喜欢</p>
        <div class="hotspotBox">
          <div class="hotspotBoxSm" v-for="(item, index) in list" :key="index"  @click="goodsDetail(item)">
            <img :src="item.goodsPic" alt="" />
            <p class="hotspotBoxSmp">{{ item.goodsName }}</p>
            <div class="hotspotBoxPri">
              <div>
                <span>¥{{ item.goodsDiscountPrice }}</span>
                <span>¥{{item.goodsCommodityPrice}}</span>
              </div>
              <div>销量:{{item.goodsSalesNum || 0}}</div>
            </div>
          </div>
        </div>
        <Row class="mb20">
          <Page
            class="mt20 mr20 fr"
            size="small"
            show-elevator
            show-sizer
            show-total
            :total="total"
            :page-size="searchForm.pageSize"
            :current.sync="searchForm.page"
            @on-change="homePageYoulikeList"
            @on-page-size-change="
              (pageSize) => {
                (searchForm.pageSize = pageSize), homePageYoulikeList();
              }
            "
          />
        </Row>
      </div>
      <!-- 导航 -->
      <div class="nas" v-if="this.nasBoo">
        <ul>
          <li @click="roClick('recommend')">推荐陵园</li>
          <li @click="roClick('goldStore')">金牌店铺</li>
          <li @click="roClick('active')">活动专区</li>
          <li @click="roClick('care')">关爱老人</li>
          <li @click="roClick('hotspot')">精选热点</li>
          <li @click="roClick('love')">猜你喜欢</li>
          <li><icon class="iconfont iconkefu2"></icon>客服</li>
          <li><icon class="iconfont icondianhua1"></icon>电话</li>
          <li style="border: none"  @click="roClick('top')">
              <icon class="iconfont iconxiangshang"></icon>顶部
            </li>
        </ul>
      </div>
    </div>

    <!-- 视频弹框 -->
    <div class="videoModal" v-if="isShowVideo">
      <div class="videoContent">
        <video controls autoplay :src="activeOne[0].activitiesThumb"></video>
        <Icon
          class="close"
          @click="isShowVideo = false"
          type="ios-close-circle"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import Swiper from "swiper";
import api from "@/util/api";
import instance from "@/util/instance";
// import BaiduMap from "vue-baidu-map/components/map/Map";
// import getCurrentCityName from '../../../filters/index'
export default {
  data() {
    return {
      goodsId: 0,
      scrollTop: 0,
      value2: 0,
      nasBoo: false,
      isShowVideo: false,
      goodsItem: ["全部排行"],
      // randList: [
      //   { src: require("@/assets/images/one.png") },
      //   { src: require("@/assets/images/two.png") },
      //   { src: require("@/assets/images/three.png") },
      //   { src: require("@/assets/images/four.png") },
      //   { src: require("@/assets/images/five.png") },
      //   { src: require("@/assets/images/six.png") },
      // ],
      data: {},
      aLi: {},
      aList: [],
      userInfo: JSON.parse(sessionStorage.getItem("userInfo") || "{}"),
      total: 0,
      searchForm: {
        page: 1,
        pageSize: 10,
        search: {},
      },
      totalO: 0,
      searchFormO: {
        page: 1,
        pageSize: 10,
        search: {},
      },
      list: [],
      goodList: [],
      selectList: [],
      activeOne: [],
      activeTwo: [],
      ipItem:{},
    };
  },
  components: {},
  mounted() {
    this.swiper();
    window.addEventListener("scroll", this.getScroll);
    api.homePageinsertIP({},res=>{
      this.ipItem=res.data
      this.search();
      this.homePageYoulikeList(); //猜你喜欢
      this.homePageGoodList();
      this.actO(); //关爱老人视频
      this.actT(); //关爱老人视频
      this.homePageSelectList(); //精选热点
      // this.getCurrentCity();
    })
    console.log("instanceinstanceinstance",instance);
  },
  destroyed() {
    window.removeEventListener("scroll", this.getScroll);
  },
  methods: {
    // getCurrentCityName(){
      
    // },
    getCurrentCity() {
        getCurrentCityName().then((city) => {
          console.log("打印>>>",city);  //顺利的话能在控制台打印出当前城市
        })
    },
    roClick(name){
      document.querySelector(`#${name}`).scrollIntoView(true)
    },
    routeClick(item) {
      this.$router.push({ path: "/ShopDetail" });
      sessionStorage.setItem("businessId", item.id);
      sessionStorage.setItem("companyId", 0);
    },
    getUrlCode(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    search() {
      api.homePageMain({cityId:this.ipItem.cityId,provinceId:this.ipItem.provinceId}, (res) => {
        this.data = res.data;
        this.aLi = res.data.companyData[0];
        let arr = res.data.companyData;
        this.aList = arr.slice(1);
      });
    },
    swiper() {
      new Swiper("#swiper1", {
        loop: true,
        // speed:10000,//匀速时间
        // 如果需要分页器
        pagination: ".swiper-pagination",
        // 如果需要前进后退按钮
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        autoplay: 10000,
      });
      new Swiper("#swiper2", {
        loop: true,
        pagination: "#swiper-pagination",
        autoplay: 10000,
      });
    },

    goodsIdFun(e) {
      this.goodsId = e;
    },
    getScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (
        !!document.documentElement.scrollTop &&
        document.documentElement.scrollTop >= 650
      ) {
        this.nasBoo = true;
      } else {
        this.nasBoo = false;
      }
    },
    //猜你喜欢
    homePageYoulikeList() {
      let searchForm = this.searchForm;
      searchForm.search.cityId=this.ipItem.cityId;
      searchForm.search.provinceId=this.ipItem.provinceId;
      api.homePageYoulikeList(searchForm, (res) => {
        this.list = res.resultList;
        this.total = res.resultCount || 0;
      });
    },
    //好物排行
    homePageGoodList() {
      let searchForm = { page: 1, pageSize: 10, search: {} };
      searchForm.search.cityId=this.ipItem.cityId;
      searchForm.search.provinceId=this.ipItem.provinceId;
      api.homePageGoodList(searchForm, (res) => {
        this.goodList = res.resultList.slice(0, 6);
      });
    },
    //关爱老人
    actO() {
      let searchForm = { page: 1, pageSize: 10, search: {} };
      searchForm.search.activitiesType = 2;
      searchForm.search.cityId=this.ipItem.cityId;
      searchForm.search.provinceId=this.ipItem.provinceId;
      api.publicaAtiivitiesActiivitiesPage(searchForm, (res) => {
        this.activeOne = res.resultList.slice(0, 1);
      });
    },
    actT() {
      let searchForm = { page: 1, pageSize: 10, search: {} };
      searchForm.search.activitiesType = 1;
      searchForm.search.cityId=this.ipItem.cityId;
      searchForm.search.provinceId=this.ipItem.provinceId;
      api.publicaAtiivitiesActiivitiesPage(searchForm, (res) => {
        this.activeTwo = res.resultList.slice(0, 3);
      });
    },
    //精选热点
    homePageSelectList() {
      let searchForm = this.searchFormO;
      searchForm.search.cityId=this.ipItem.cityId;
      searchForm.search.provinceId=this.ipItem.provinceId;
      api.homePageSelectList(searchForm, (res) => {
        this.selectList = res.resultList;
        this.totalO = res.resultCount || 0;
      });
    },
    actClick(id) {
      this.$router.push({ path: "/activeText", query: { iId: id } });
    },
    // goodsDetail(item) {
    //   sessionStorage.setItem("itemDetails", JSON.stringify(item));
    //   this.$router.push({ path: "/GoodsDetail" });
    // },
  },
};
</script>

<style lang="less" scoped>
// element.style {
//     transform: matrix(1, 0, 0, 1, 0, 0);
//     transition: all 500ms linear 0s;
// }
.Index {
  background: #f8f8f8;
}
.swiper {
  position: relative;
  height: 400px;
  margin: 0;
  padding: 0;
}
.swiper-container {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  border: none;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  // background: #fff;
  border: none;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.solid {
  width: 1px;
  height: 141px;
  background: linear-gradient(
    180deg,
    rgba(41, 150, 84, 0) 0%,
    rgba(41, 150, 84, 0.2) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
}
.mensList {
  width: 100%;
  height: 141px;
  background: linear-gradient(
    180deg,
    rgba(41, 150, 84, 0.2) 0%,
    rgba(41, 150, 84, 0) 100%
  );
  border-bottom: 1px solid #e7f0ea;
  .mensBox {
    width: 1120px;
    height: 141px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    div {
      flex: 1;
      text-align: center;
      position: relative;
      img {
        width: 48px;
        height: 48px;
        margin: 16px auto 0;
        margin-top: 16rpx;
      }
      .p1 {
        font-size: 18px;
        color: #1a1a1a;
        line-height: 25px;
        letter-spacing: 1px;
        font-weight: 550;
        margin-top: 12px;
      }
      .p2 {
        margin-top: 4px;
        font-size: 14px;
        font-weight: 500;
        color: #797979;
        line-height: 20px;
      }
    }
  }
}
.bodyBox {
  width: 1120px;
  margin: auto;
}
.about {
  width: 100%;
  // height: 280px;
  margin: 40px auto 0;
  background: url("../../../assets/images/bgmund.png") center no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 10px;
  // border:1px solid red;
}
.aboutImg {
  height: 240px;
  width: 478px;
  position: absolute;
  top: 20px;
  right: 60px;
  z-index: 0;
}
.about > p:nth-child(2) {
  height: 33px;
  font-size: 24px;
  font-weight: bold;
  color: #1a1a1a;
  line-height: 33px;
  letter-spacing: 1px;
  margin: 40px 0 0 24px;
  position: relative;
  z-index: 1;
}
.about > p:nth-child(3) {
  width: 548px;
  height: 120px;
  font-size: 16px;
  font-weight: 400;
  color: #838383;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 20px 0 0 24px;
  position: relative;
  z-index: 1;
}
.about > p:nth-child(4) {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #299654;
  line-height: 22px;
  letter-spacing: 1px;
  margin: 15px 0 0 24px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  i {
    font-size: 14px;
  }
}
.recommend {
  width: 1120px;
  height: 513px;
  margin: 20px auto;
  background: #ffffff;
  box-sizing: border-box;
  padding: 18px;
  cursor: pointer;
  .recommendTitle {
    width: 100%;
    height: 33px;
    font-size: 24px;
    font-weight: bold;
    color: #1a1a1a;
    line-height: 33px;
    letter-spacing: 1px;
  }
  .recommendBox {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    .recommendBoxLeft {
      width: 310px;
      height: 426px;
      background: #fbfbfc;
      border-radius: 2px;
      position: relative;
      padding: 24px 24px 0;
      .todayJx {
        position: absolute;
        top: 0;
        left: 0;
        width: 88px;
        height: 28px;
        line-height: 28px;
        background: linear-gradient(135deg, #f3c455 0%, #f6b73d 100%);
        border-radius: 2px 0px 10px 0px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
      }
      .imgJx {
        width: 260px;
        height: 260px;
        background: #d8d8d8;
        border-radius: 2px;
        margin-top: 10px;
      }
      .swTitle {
        margin-top: 8px;
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1a1a1a;
        line-height: 25px;
        text-align: center;
      }
      .swdes {
        width: 260px;
        height: 24px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #838383;
        line-height: 24px;
        letter-spacing: 1px;
        margin-top: 4px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
      }
      .swprice {
        margin-top: 10px;
        height: 33px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #e64747;
        line-height: 33px;
        letter-spacing: 1px;
        text-align: center;
        span {
          height: 24px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #838383;
          line-height: 24px;
          letter-spacing: 1px;
        }
      }
      .swheig {
        height: 24px;
      }
    }
    .recommendBoxRight {
      width: 772px;
      display: flex;
      flex-wrap: wrap;
      margin-left: 4px;
      .recommendBoxRightBox:nth-child(2n) {
        margin-left: 13px;
      }
      .recommendBoxRightBox {
        width: 373px;
        height: 207px;
        display: flex;
        justify-content: space-between;
        .imgRig {
          width: 207px;
          height: 207px;
        }
        div {
          margin: 35px 12px 0 8px;
        }
        div > p:nth-child(1) {
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #1a1a1a;
          line-height: 22px;
        }
        div > p:nth-child(1):hover {
          color: #299654;
        }
        div > p:nth-child(2) {
          margin-top: 16px;
          width: 152px;
          font-size: 14px;
          font-weight: 400;
          color: #838383;
          line-height: 18px;
          display: -webkit-box;
          overflow: hidden;
          white-space: normal !important;
          text-overflow: ellipsis;
          word-wrap: break-word;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        div > p:nth-child(3) {
          margin-top: 23px;
          height: 24px;
          font-size: 12px;
          font-weight: 400;
          color: #838383;
          line-height: 24px;
          span {
            font-size: 16px;
            font-weight: 400;
            color: #e64747;
            margin-right: 2px;
          }
        }
      }
    }
  }
}
.goIdBox {
  width: 1120px;
  margin: 20px auto;
}
.goldStore {
  // margin: 20px auto;
  width: 100%;
  // height: 507px;
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;
  cursor: pointer;
  .goldStoreTitle {
    height: 33px;
    font-size: 24px;
    font-weight: 400;
    color: #1a1a1a;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    .iconxiangyou1 {
      font-size: 18px;
      font-weight: 600;
      line-height: 33px;
      color: rgba(41, 150, 84, 1);
      margin: 2px 0 0 8px;
    }
  }
  .goldStoreLeft {
    width: 554px;
    height: 507px;
    background: #ffffff;
    padding: 24px;
    .goldStoreLeftB {
      display: flex;
      flex-wrap: wrap;
    }
    .goldStoreLeftBox:nth-child(2n) {
      margin-left: 14px;
    }
    .goldStoreLeftBox {
      margin-top: 20px;
      p {
        width: 100%;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #1a1a1a;
        line-height: 22px;
      }
      div {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        width: 246px;
        img {
          width: 163px;
          height: 163px;
          border-radius: 2px;
        }
        div {
          height: 163px;
          width: 77px;
          display: flex;
          flex-wrap: wrap;
          img {
            width: 77px;
            height: 77px;
            border-radius: 2px;
          }
          img:nth-child(1) {
            margin-top: -9px;
          }
          img:nth-child(2) {
            margin-top: 2px;
          }
        }
      }
    }
  }
  .goldStoreRight {
    width: 554px;
    height: 507px;
    background: #ffffff;
    padding: 24px;
    margin-left: 12px;
    .goldStoreRightTop {
      display: flex;
      justify-content: space-between;
      div {
        display: flex;
        justify-content: space-between;
        line-height: 40px;
        div {
          // width:70px;
          // line-height:;
          text-align: center;
          margin-right: 20px;
        }
      }
    }
    .goldStoreRightBody {
      height: 400px;
      display: -webkit-flex; /* Safari */
      -webkit-flex-flow: row-reverse wrap; /* Safari 6.1+ */
      display: flex;
      flex-flow: column;
      flex-wrap: wrap;
      .goldStoreRightBodyBox:nth-child(n + 4) {
        margin-left: 12px;
      }
      .goldStoreRightBodyBox {
        width: 247px;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 32px;
        .topImg {
          width: 38px;
          height: 56px;
        }
        .goldStoreRightBodyBoxmain {
          width: 201px;
          height: 100px;
          display: flex;
          justify-content: space-between;
          img {
            width: 100px;
            height: 100px;
          }
          div {
            p {
              width: 97px;
              height: 40px;
              font-size: 14px;
              font-weight: 400;
              color: #1a1a1a;
              line-height: 20px;
              display: -webkit-box;
              overflow: hidden;
              white-space: normal !important;
              text-overflow: ellipsis;
              word-wrap: break-word;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            p:nth-child(1):hover {
              color: #299654;
            }
            p:nth-child(2) {
              margin-top: 12px;
              span:nth-child(1) {
                height: 22px;
                font-size: 16px;
                font-weight: 400;
                color: #e64747;
                line-height: 22px;
              }
              span:nth-child(2) {
                height: 14px;
                font-size: 10px;
                font-weight: 400;
                color: #838383;
                line-height: 14px;
                text-decoration: line-through;
              }
            }
          }
        }
      }
    }
  }
}
.active {
  width: 1120px;
  height: 320px;
  margin: 20px auto 0;
  display: flex;
  justify-content: space-between;
  img {
    width: 219px;
    height: 320px;
    cursor: pointer;
  }
  .activeBox {
    width: 889px;
    height: 320px;
    background: #ffffff;
    border-radius: 2px;
    display: flex;
    flex-wrap: nowrap;
    cursor: pointer;
    div:nth-child(n + 1) {
      border-left: 1px solid #e9e9e9;
    }
    div {
      width: 222px;
      padding: 34px 31px;
      cursor: pointer;
      img {
        width: 160px;
        height: 160px;
        border-radius: 2px;
        margin: auto;
        cursor: pointer;
      }
      p:nth-child(2):hover {
        color: #299654;
      }
      p:nth-child(2) {
        width: 160px;
        height: 36px;
        font-size: 14px;
        font-weight: 400;
        color: #1a1a1a;
        line-height: 18px;
        display: -webkit-box;
        overflow: hidden;
        white-space: normal !important;
        text-overflow: ellipsis;
        word-wrap: break-word;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top: 12px;
      }
      p:nth-child(3) {
        margin-top: 20px;
        text-align: center;
        span:nth-child(1) {
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #e64747;
          line-height: 22px;
        }
        span:nth-child(2) {
          height: 14px;
          font-size: 10px;
          font-weight: 400;
          color: #838383;
          line-height: 14px;
          text-decoration: line-through;
        }
      }
    }
  }
}
.care {
  width: 1120px;
  margin: 20px auto 0;
  padding: 24px 24px 0;
  background: #ffffff;
  .goldStoreTitle {
    height: 33px;
    font-size: 24px;
    font-weight: 400;
    color: #1a1a1a;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .iconxiangyou1 {
      font-size: 18px;
      font-weight: 600;
      line-height: 33px;
      color: rgba(41, 150, 84, 1);
      margin: 2px 0 0 8px;
    }
  }
  .careBox {
    display: flex;
    justify-content: space-between;
    .careBoxLeft {
      cursor: pointer;
      width: 524px;
      margin-top: 12px;
      padding-bottom: 24px;
      position: relative;
      .img {
        width: 524px;
        height: 360px;
        border-radius: 2px;
      }
      video:focus {
        outline: none;
      }
      .videoPlay {
        position: absolute;
        width: 80px;
        height: 80px;
        left: 210px;
        top: 140px;
        cursor: pointer;
      }
      p {
        margin-top: 12px;
      }
    }
    .careBoxRight {
      cursor: pointer;
      width: 524px;
      margin-top: 12px;
      border-left: 1px solid #e9e9e9;
      padding-left: 12px;
      padding-bottom: 24px;
      .careBoxRightBox {
        // width:524px;
        padding-bottom: 6px;
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        img {
          width: 120px;
          height: 120px;
        }
        div {
          margin-left: 16px;
          p:nth-child(1) {
            width: 360px;
            text-align: left;
            height: 25px;
            font-size: 18px;
            font-weight: 400;
            color: #1a1a1a;
            line-height: 25px;
          }
          p:nth-child(2) {
            margin-top: 16px;
            width: 360px;
            height: 36px;
            font-size: 16px;
            font-weight: 400;
            color: #838383;
            line-height: 18px;
            letter-spacing: 1px;
            display: -webkit-box;
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          p:nth-child(2):hover {
            color: #299654;
          }
          p:nth-child(3) {
            width: 360px;
            display: flex;
            justify-content: space-between;
            margin-top: 12px;
            span {
              height: 24px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #838383;
              line-height: 24px;
            }
          }
        }
      }
      .careBoxRightBox:nth-child(n + 1) {
        border-bottom: 1px dashed #838383;
      }
    }
  }
}
// -------广告-----------
.news {
  width: 1120px;
  height: 100px;
  margin: 40px auto 0;
  background: url("../../../assets/images/news.png") center no-repeat;
  background-size: cover;
}

.hotspot {
  width: 1120px;
  // height: 730px;
  background: #ffffff;
  margin: 20px auto 0;
  padding: 24px;
  .goldStoreTitle {
    height: 33px;
    font-size: 24px;
    font-weight: 400;
    color: #1a1a1a;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .iconxiangyou1 {
      font-size: 18px;
      font-weight: 600;
      line-height: 33px;
      color: rgba(41, 150, 84, 1);
      margin: 2px 0 0 8px;
    }
  }
  .hotspotTit {
    display: flex;
    flex-wrap: nowrap;
    height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #c0c0c0;
    line-height: 17px;
    margin-top: 12px;
    span {
      cursor: pointer;
    }
    span:not(:nth-child(1)) {
      margin-left: 20px;
    }
    span:hover {
      color: rgba(41, 150, 84, 1);
    }
  }
  .hotspotBox {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    .hotspotBoxSm:hover {
      cursor: pointer;
      transition: all 0.2s;
      transform: scale(1.1);
      border: 1px solid rgba(41, 150, 84, 1);
    }
    .hotspotBoxSm {
      padding: 12px 18px;
      width: 214px;
      height: 299px;
      background: #ffffff;
      border: 1px solid #f8f8f8;
      cursor: pointer;
      img {
        width: 176px;
        height: 175px;
        margin-bottom: 8px;
      }
      .hotspotBoxSmp {
        width: 176px;
        height: 48px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4d4d4d;
        line-height: 24px;
        letter-spacing: 1px;
        display: -webkit-box;
        overflow: hidden;
        white-space: normal !important;
        text-overflow: ellipsis;
        word-wrap: break-word;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .hotspotBoxPri {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        div:nth-child(1) {
          span:nth-child(1) {
            height: 33px;
            font-size: 24px;
            font-weight: 400;
            color: #e64747;
            line-height: 33px;
          }
          span:nth-child(2) {
            height: 17px;
            font-size: 12px;
            font-weight: 400;
            color: #838383;
            line-height: 17px;
            text-decoration: line-through;
          }
        }
        div:nth-child(2) {
          height: 17px;
          font-size: 12px;
          font-weight: 400;
          color: #838383;
          line-height: 42px;
        }
      }
    }
  }
}
.boxx {
  position: relative;
}
@media screen and (max-width: 1940px) {
  .nas {
    position: fixed;
    top: 60px;
    right: 130px;
  }
}
@media screen and (max-width: 1550px) {
  .nas {
    position: fixed;
    top: 60px;
    right: 60px;
  }
}
@media screen and (max-width: 1380px) {
  .nas {
    position: fixed;
    top: 60px;
    right: 230px;
    display: none;
  }
}

.nas {
  ul a li:not(:last-child) {
    border-bottom: 1px solid rgba(192, 192, 192, 0.4);
  }
  ul {
    display: flex;
    width: 58px;
    width: 58px;
    background: #ffffff;
    flex-wrap: wrap;
    list-style: none;
    overflow: hidden;
    li {
      display: inline-block;
      cursor: pointer;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1a1a1a;
      line-height: 20px;
      letter-spacing: 1px;
      margin: 0 14px;
      padding: 12px 0;
      border-bottom: 1px solid rgba(192, 192, 192, 0.4);
      &.acitve {
        color: #299654;
        font-weight: 600;
      }
    }
    li:hover {
      color: #299654;
      font-weight: 600;
    }
  }
  .iconkefu2 {
    margin-left: 6px;
    font-size: 18px;
    font-weight: 400;
  }
  .icondianhua1 {
    margin-left: 6px;
    font-size: 18px;
    font-weight: 400;
  }
  .iconxiangshang {
    margin-left: 6px;
    font-size: 18px;
    font-weight: 400;
  }
}
.videoModal {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  .videoContent {
    position: absolute;
    width: 1120px;
    height: 800px;
    background: #ffffff;
    border-radius: 4px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    video {
      width: 100%;
      height: 100%;
    }
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 40px;
      color: rgba(0, 0, 0, 0.2);
      z-index: 9;
      cursor: pointer;
    }
  }
}
</style>