import {instance} from  '@/util/http';
export default {
    //大转盘根据ID查询活动详情
    activity_dzp: (data, cb) => {
        instance.get(`/xfd/c/activity_dzp/${data.dzpId}`, {}).then(res => { cb && cb(res)})
    },
    ////大转盘根据ID查询活动详情
    activity_dzp_do: (data, cb) => {
        instance.get(`/xfd/c/activity_dzp/do/${data.dzpId}?id=${data.id}`, {}).then(res => { cb && cb(res)})
    },
    //一键发圈根据ID查询发圈详情
    activityShareMoment: (data, cb) => {
        instance.get(`/xfd/c/activity_share/moment/${data.dzpId}`, {}).then(res => { cb && cb(res)})
    },
    //根据条件分页查询优惠券模板
    couponTemplatePageAll: (data, cb) => {
        instance.get(`/xfd/c/coupon_template/page/all`, {params:data}).then(res => { cb && cb(res)})
    },
    //根据条件分页查询活动基础数据
    activityBasicPageAll: (data, cb) => {
        instance.get(`/xfd/c/activity_basic/page/all`, {params:data}).then(res => { cb && cb(res)})
    },
    //用户所有可用的优惠劵//下订单时查询用户优惠劵
    couponPageAll: (data, cb) => {
        instance.get(`/xfd/c/coupon/page/all`, {params:data}).then(res => { cb && cb(res)})
    },
    //用户所有已用过的优惠劵
    couponPageCouponNOList: (data, cb) => {
        instance.get(`/xfd/c/coupon/page/couponNOList`, {params:data}).then(res => { cb && cb(res)})
    },
    //用户所有过期的优惠劵
    couponPageCouponOutList: (data, cb) => {
        instance.get(`/xfd/c/coupon/page/couponOutList`, {params:data}).then(res => { cb && cb(res)})
    },
    //保存客户优惠券领取表
    coupon: (data, cb) => {
        instance.post(`/xfd/c/coupon`, {...data}).then(res => { cb && cb(res)})
    },
    //用户今日剩余抽奖次数
    activityDzpUser: (data, cb) => {
        instance.get(`/xfd/c/activity_dzp_user/${data.dzpId}?id=${data.id}`, {}).then(res => { cb && cb(res)})
    },
    //用户抽奖次数浏览次数+1
    addViewCount: (data, cb) => {
        instance.get(`/xfd/c/activity_dzp/${data.dzpId}/add_view_count`, {}).then(res => { cb && cb(res)})
    },
    //下订单时查询用户优惠劵
    couponPageAllOder: (data, cb) => {
        instance.post(`/xfd/c/coupon/page/allOder?size=${data.size}&current=${data.current}`, {...data}).then(res => { cb && cb(res)})
    },
    //商户详情
    shopBusinessDetail: (data, cb) => {
        instance.get(`/xfd/c/shop/businessDetail`, {params:data}).then(res => { cb && cb(res)})
    },
    //推荐有礼领券
    activityTjReceive: (data, cb) => {
        instance.get(`/xfd/c/activity_tj/receive`, {params:data}).then(res => { cb && cb(res)})
    },
    //根据条件分页查询商品优惠券
    goodsPageAll: (data, cb) => {
        instance.get(`/xfd/c/goods/page/all`, {params:data}).then(res => { cb && cb(res)})
    },
}   